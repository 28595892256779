<template>
	<div class="row m-0">
      <div class="col-12 py-5 text-center" v-if="resultado.length == 0">Nenhum salário encontrado</div>

      <div class="col-12 p-0" v-else>
         <div class="cards-2">
            <salario v-for="(salario, index) in resultado" :key="index" :salario="salario" :index="index" @removeSalary="removeSalary($event)" />
         </div>
      </div>

      <a href="javascript:;" class="btn-float btn-primary" @click="showAddSalary">
         <i class="fas fa-plus"></i>
      </a>

      <!-- Modal salario -->
      <div class="modal fade" id="modalAddSalary">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h4 class="modal-title" id="modalAddSalaryLabel">Adicionar salário</h4>
               </div>
               <div class="modal-body py-3 pb-5">
                  <div class="card mb-5">
                     <div class="card-body p-3">
                        <div class="row m-0">
                           <div class="col-12 p-0 mb-2">
                              <label class="form-label"><i class="far fa-lock font-12 color-theme me-1"></i> Permissão</label>
                              <v-select :options="listaPermissoes" v-model="add.perm" placeholder="Selecione uma opção" />
                           </div>
                           <div class="col-12 p-0">
                              <label class="form-label"><i class="far fa-money-bill-wave-alt font-12 color-theme me-1"></i> Salário</label>
                              <input type="text" class="form-control" v-model="add.salario" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-success waves-effect me-2" @click="addSalary">
                     <i class="far fa-check font-12 me-2"></i>Salvar
                  </button>
                  <button type="button" class="btn btn-danger btn-simple waves-effect" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import salario from '@/components/admin/salarios/Salario.vue'

export default {
	name: 'AdmSalários',
   data: function () {
      return {
         add: {'perm': null, 'salario': 0},
         resultado: []
      }
   },
   computed: {
      ... mapState({
         listaPermissoes: state => state.listaPermissoes,
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   components: {
      salario
   },
   methods: {
      showAddSalary : function() {
         $('#modalAddSalary').modal('show')
      },
      getSalary : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.resultado = []

         this.$axios({
            method: 'get',
            url: this.urlRest +'game/getSalary',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername 
            }
         }).then(response => {
				this.resultado = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      removeSalary : function (salario) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Remover salário',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)

               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'game/removeSalary',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     id: salario.id
                  }
               }).then(function () {
                  ref.resultado.splice(salario.index, 1)

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Salário removido!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         })
      },
      addSalary : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'game/addSalary',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername
            },
            params:{
               perm: this.add.perm,
               salary: this.add.salario
            }
         }).then(function (response) {
            ref.resultado = response.data

            ref.$toast.fire({
               icon: 'success',
               title: 'Salário criado!'
            })
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      }
   },
   mounted() {
      this.$store.dispatch('buscarPermissoes')
      this.getSalary()
   }
}

</script>