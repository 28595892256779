<template>
   <div class="card mb-0">
      <div class="card-body p-3 text-center removeToHover" @click="removeSalary">
         <h5 class="mb-2 font-16 limitador">{{ salario.permission }}</h5>
         <h5 class="mb-2 font-16"><i class="far fa-trash mt-1"></i><br>Clique para remover</h5>
         <p class="m-0 font-13 limitador"> 
            <i class="fas fa-long-arrow-right font-11 color-theme me-1"></i><small class="text-secondary">Salário:</small> {{ salario.salary }} 
         </p>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Salario',
   props: ['salario', 'index'],
   methods: {
      removeSalary : function () {
         this.$emit('removeSalary', {'id': this.salario.id, 'index': this.index})
      }
   }
}

</script>

<style scoped>

.removeToHover {
	cursor: pointer;
}

.removeToHover h5:nth-child(2) {
	display: none !important;
}

.removeToHover h5:nth-child(2) i {
	font-size: 80%;
}

.removeToHover:hover > * {
	display: none !important;
}

.removeToHover:hover h5:nth-child(2) {
	display: block !important;
	margin: 0 !important;
	color: #FF3636;
}

</style>